import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import * as CloudFront from 'aws-sdk/clients/cloudfront';
import {UtilsService} from "./utils.service";
import * as moment from 'moment';
import {SessionStorageService} from "./session.storage.service";
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AwsService {
  constructor(private utilsService:UtilsService,
            private http: HttpClient,
              private sessionStorageService:SessionStorageService) {
  }
  fileUpload(file,cb) {
    const login = JSON.parse(this.sessionStorageService.get('login_credential'))
    AWS.config.region = environment.AwsRegion
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
      Logins: login,
    });

    const contentType = file.type;
    const bucket = new S3(
      {
        region: 'ca-central-1',
      }
    );
    const logged = this.sessionStorageService.getLoggedUser()
    const fileNameArr = file.name.split('.')
    const ext = fileNameArr[fileNameArr.length-1]
    const filename = this.utilsService.getRandomString(10)+"."+ext
    // console.log(logged)
    const params = {
      Bucket: 'crmmoneluupload',
      Key: logged.organization.id+'/'+filename,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        // console.log('EROOR: ',JSON.stringify( err));
        return false;
      }
      // console.log('File Uploaded.', data);
      cb(data)
      return true;
    });
  }
  uploadfileAWSS3(fileuploadurl, contenttype, file) { 
 
    const headers = new HttpHeaders({ 'Content-Type': contenttype });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers, 
      });
    return this.http.request(req);
  }

  
  async getFileUploadCrm() {
    return new Promise((resolve, reject) => {
      const login = JSON.parse(this.sessionStorageService.get('login_credential'))
      AWS.config.region = environment.AwsRegion
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
        Logins: login,
      });
      const bucket = new S3(
        {
          region: 'ca-central-1',
        }
      );
      // console.log(logged)
      const params = {
        Bucket: 'crmupload',
        Key: '23/CSpCXfy0Ao.png',
        Expires: 60
      };

      var url = bucket.getSignedUrl('getObject', params);
      // console.log('The URL is', url);

      return resolve(url)
      
    })
    
  }

  async fileUploadCrm(file) {
    return new Promise((resolve, reject) => {
      const login = JSON.parse(this.sessionStorageService.get('login_credential'))
    AWS.config.region = environment.AwsRegion
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
      Logins: login,
    });
      const contentType = file.type;
    const bucket = new S3(
      {
        region: 'ca-central-1',
      }
    );
      const logged = this.sessionStorageService.getLoggedUser()
      const fileNameArr = file.name.split('.')
      const ext = fileNameArr[fileNameArr.length-1]
      const filename = this.utilsService.getRandomString(10)+"."+ext
      // console.log(logged)
      const params = {
        Bucket: 'crmmoneluupload',
        Key: logged.organization.id+'/'+filename,
        Body: file.is_json?JSON.stringify(file.body):file,
        // ACL: 'public-read',
        ContentType: contentType
      };
      bucket.upload(params, function (err, data) {
        if (err) {
          // console.log('EROOR: ',JSON.stringify( err));
          reject(err);
        }
        console.log('File Uploaded.', data);
        resolve(data)
      });
    })
   
  }

  getFile(key: string, cb){
    const login = JSON.parse(this.sessionStorageService.get('login_credential'))
    AWS.config.region = environment.AwsRegion
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
      Logins: login,
    });
    const bucket = new S3(
      {
        region: 'ca-central-1',
      }
    );

    const params = {
      Bucket: 'crmmoneluupload',
      Key: key
    };

    bucket.getObject(params, cb)
  }


  uploadTranslateFile(language,data,cb) {
    

  }


  fileUploadCrop(file,cb) {
    const login = JSON.parse(this.sessionStorageService.get('login_credential'))
    AWS.config.region = environment.AwsRegion
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
      Logins: login,
    });

    const contentType = "image/png";

    const bucket = new S3(
      {
        region: 'ca-central-1'
      }
    );

    const logged = this.sessionStorageService.getLoggedUser()

    const ext = "png"
    const filename = this.utilsService.getRandomString(10)+"."+ext

    const params = {
      Bucket: 'crmmoneluupload',
      Key: logged.organization.id+'/'+filename,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        // console.log('EROOR: ',JSON.stringify( err));
        return false;
      }
      // console.log('File Uploaded.', data);
      cb(data)
      return true;
    });
  }
}
