import { SessionStorageService } from './session.storage.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject ,  Observable ,  BehaviorSubject } from 'rxjs';
import * as AWS from 'aws-sdk/global';


import {
	CognitoUserPool,
	CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession
} from 'amazon-cognito-identity-js';

import {ApiUserService} from "./api/api-user.service";

const POOL_DATA = {
	UserPoolId:environment.AwsUserPoolId, // Your user pool id here
	ClientId: environment.AwsClientId, // Your client id here
};
const userPool = new CognitoUserPool(POOL_DATA);

import { UserModel } from '../models/user.model';
import {Profile} from '../models/profile.model';
import { Signup } from "../../feature/public/signup/signup.model";
import {UserModule} from "../../feature/user/user.module";
import { SessionExpirationCheckService } from './session-expiration-check.service';


@Injectable()
export class AuthService {
  authIsLoading = new BehaviorSubject<boolean>(false);
  authMessage = new BehaviorSubject<string>('');
  forgotIsLoading = new BehaviorSubject<boolean>(false);
  authDidFail = new BehaviorSubject<boolean>(false);
  isRegistered = new BehaviorSubject<any>(false)
  isPhoneVerify = new BehaviorSubject<boolean>(false)
  isEnableMFA  = new BehaviorSubject<boolean>(false)

  savedCognitoUser:CognitoUser

  phoneVerify = new Subject<any>()
  signInSubmit = new Subject<any>()

  listDevices   = new Subject<any>()
  currentDevice   = new Subject<any>()

  onAuthenticatorApp = new Subject<any>()

  authStatusChanged = new Subject<boolean>();
  registeredUser: CognitoUser
  userLogged = new Subject<object>();
  constructor(
    private router: Router,
    private apiService:ApiUserService,
    private sessionStorageService:SessionStorageService,
    private sessionExpirationCheckService: SessionExpirationCheckService
    ) {}

  signUp(username: string, email: string, password: string,profile:Profile): void {
    this.authIsLoading.next(true);
    const user = new UserModel();
    user.email = email
    user.password = password
    const pro:Profile = profile


    const attrList: CognitoUserAttribute[] = [];
    const emailAttribute = {
      Name: 'email',
      Value: user.email
    };
    attrList.push(new CognitoUserAttribute(emailAttribute))
    for(let key in pro) {
      var value = pro[key];
      var name = key;
      if(key === 'timezone' || key === 'language'){
        name ="custom:"+key
      }
      const attribute = {
        Name: name,
        Value: value
      };
      attrList.push(new CognitoUserAttribute(attribute))
    }

    // console.log(attrList)

    userPool.signUp(user.email, user.password,attrList,null,(err,result) => {
      if (err) {
        this.authMessage.next(err.message)
        this.authDidFail.next(true)
        this.authIsLoading.next(false)
        return
      }
      // console.log(result)
      this.authDidFail.next(false)
      this.authIsLoading.next(false)
      this.isRegistered.next(result)
      this.registeredUser = result.user
    })

    return;
  }

  async confirmRegistration(email,profile,code){
    return new Promise((resolve,reject)=>{
      const userData={
        Username: email,
        Pool: userPool
      }
      const pro:Profile = profile
      const cognitoUser = new CognitoUser(userData)
      cognitoUser.confirmRegistration(code, true, (err, result)=> {
        if (err) {
          resolve(err.message || JSON.stringify(err));
          return;
        }
        console.log('call result: ' + result);
        const signup = new Signup()
        signup.first_name = pro.name
        signup.last_name = pro.family_name
        signup.email = email
        this.apiService.signUp(signup).subscribe(res=>{
          if(res.status === "ERROR"){
            resolve(res.message)
          }else{
            resolve('success')
            this.router.navigate([''])
          }
        })
      });
    })
  }


  async resendConfirmRegistration(email){
    return new Promise((resolve,reject)=>{
      const userData={
        Username: email,
        Pool: userPool
      }
      const cognitoUser = new CognitoUser(userData)
      cognitoUser.resendConfirmationCode((err, result)=> {
        if (err) {
          resolve(err.message || JSON.stringify(err));
          return;
        }
        console.log('call result: ' + result);
        resolve('success')
      });
    })
  }
  async resendSmsCode(){
    return new Promise((resolve,reject)=>{
      this.savedCognitoUser.resendConfirmationCode((err, result)=> {
        if (err) {
          resolve(err.message || JSON.stringify(err));
          return;
        }
        console.log('call result: ' + result);
        resolve('success')
      });
    })
  }


  refreshToken(force=0){
    const cognitoUser = this.getAuthenticatedUser();
    
    cognitoUser.getSession((err, session) => {

      const refresh_token = session.getRefreshToken(); // receive session from calling cognitoUser.getSession()
        
      AWS.config.region = environment.AwsRegion;
      const user_pool_key = 'cognito-idp.'+environment.AwsRegion+'.amazonaws.com/'+environment.AwsUserPoolId
      // console.log(session)
      const login = {}
      login[user_pool_key]=session.getIdToken().getJwtToken();

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
        Logins: login,
      });

      if (session.isValid() === false || force==1) {
        cognitoUser.refreshSession(refresh_token, (err, session_new) => {
          if (err) {
            // console.log(err);
          } else {
            (AWS.config.credentials as AWS.CognitoIdentityCredentials).params['Logins'][user_pool_key] = session_new.getIdToken().getJwtToken();
            (AWS.config.credentials as AWS.CognitoIdentityCredentials).refresh(err => {
              if (err) {
                // console.log(err);
              } else {
                // Token
                // console.log('token refresh')
                const logged = this.sessionStorageService.getLoggedUser();
                logged.token = session_new.getAccessToken().getJwtToken()
                this.sessionStorageService.set("logged",logged)
                
                this.apiService.updateToken({
                  email:session_new.getIdToken().payload.email,
                  refresh_token:session_new.getRefreshToken().getToken(),
                  access_token:session_new.getAccessToken().getJwtToken(),
                }).subscribe(res=>{
                  if(res.status==="ERROR"){
                    // console.log(res.message)
                  }
                })

              }
            });
          }
        });
      }

    })

  }

  signInSelectMFA(mfaType){
    const that = this
    this.savedCognitoUser.sendMFASelectionAnswer(mfaType,{
      onSuccess(result: CognitoUserSession) {

      },
      onFailure(err){
        that.authStatusChanged.next(false)
        that.authDidFail.next(true)
        that.authIsLoading.next(false)
        that.signInSubmit.next({status:'false',message:err.message})
      },
      totpRequired: function () {
        that.signInSubmit.next({status:'software-token-mfa-input'})
        console.log('auth required')
        // const verificationCode = prompt('Please input verification code', '');
        // cognitUser.sendMFACode(verificationCode, this,"SOFTWARE_TOKEN_MFA");
      },
      mfaRequired:function () {

        that.signInSubmit.next({status:'sms-mfa-input'})
        console.log('mfa required')
        // const verificationCode = prompt('Please input verification code', '');
        // cognitUser.sendMFACode(verificationCode, this);
      }
    });
  }

  signInSendMFACode(code,mfaType,remember_me){
    const that = this
    this.savedCognitoUser.sendMFACode(code, {
      onSuccess(result: CognitoUserSession) {
        
        if(remember_me == true){

          that.savedCognitoUser.setDeviceStatusRemembered({
            onSuccess: function(result) {
              console.log('call result: ' + result);
            },
            onFailure: function(err) {
              // alert(err.message || JSON.stringify(err));
            },
          })
        }

        AWS.config.region = environment.AwsRegion;
        const user_pool_key = 'cognito-idp.'+environment.AwsRegion+'.amazonaws.com/'+environment.AwsUserPoolId
        
        const login = {}
        login[user_pool_key]=result.getIdToken().getJwtToken();

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
          Logins: login,
        });
        
        //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
        (AWS.config.credentials as AWS.CognitoIdentityCredentials).refresh(error => {
          if (error) {
            console.error(error);
          } else {
            that.apiService.logIn({
              email:result.getIdToken().payload.email,
              id_token:result.getIdToken().getJwtToken(),
              refresh_token:result.getRefreshToken().getToken(),
              access_token:result.getAccessToken().getJwtToken(),
            }).subscribe(res=>{
              if(res.status==="ERROR"){
                // console.log(res.message)
                that.authStatusChanged.next(false)
                that.authDidFail.next(true)
                that.authIsLoading.next(false)
              }else{

                that.sessionStorageService.set('logged',res.data)
                that.sessionStorageService.set('login_credential',JSON.stringify(login))


                that.authStatusChanged.next(true)
                that.authDidFail.next(false)
                that.authIsLoading.next(false)
              }
            })
          }
        }); 
      },
      onFailure(err){
        that.authStatusChanged.next(false)
        that.authDidFail.next(true)
        that.authIsLoading.next(false)
        that.signInSubmit.next({status:'false',message:err.message})
      },
    },mfaType);

  }

  signIn(username: string, password: string,remember_me): void {
    this.authIsLoading.next(true);
    const authData = {
      Username: username,
      Password: password
    };
    const authDetails = new AuthenticationDetails(authData)
    const userData={
      Username: username,
      Pool: userPool
    }
    const cognitUser = new CognitoUser(userData)
    const that = this
    // console.log('signin')
    cognitUser.authenticateUser(authDetails,{
      onSuccess(result: CognitoUserSession) {

        console.log(result)

        if(remember_me==true){

          cognitUser.setDeviceStatusRemembered({
            onSuccess: function(result) {
              console.log('call result: ' + result);
            },
            onFailure: function(err) {
              // alert(err.message || JSON.stringify(err));
            },
          })
        }

        AWS.config.region = environment.AwsRegion;
        const user_pool_key = 'cognito-idp.'+environment.AwsRegion+'.amazonaws.com/'+environment.AwsUserPoolId
        
        const login = {}
        login[user_pool_key]=result.getIdToken().getJwtToken();

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: environment.AwsIdentifyPoolId, // your identity pool id here
          Logins: login,
        });
        
        //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
        (AWS.config.credentials as AWS.CognitoIdentityCredentials).refresh(error => {
          if (error) {
            console.error(error);
          } else {
            that.apiService.logIn({
              email:result.getIdToken().payload.email,
              id_token:result.getIdToken().getJwtToken(),
              refresh_token:result.getRefreshToken().getToken(),
              access_token:result.getAccessToken().getJwtToken(),
            }).subscribe(res=>{
              if(res.status==="ERROR"){
                // console.log(res.message)
                that.authStatusChanged.next(false)
                that.authDidFail.next(true)
                that.authIsLoading.next(false)
              }else{

                that.sessionStorageService.set('logged',res.data)
                that.sessionStorageService.set('login_credential',JSON.stringify(login))


                that.authStatusChanged.next(true)
                that.authDidFail.next(false)
                that.authIsLoading.next(false)
              }
            })
          }
        }); 

      },
      onFailure(err){
        that.authStatusChanged.next(false)
        that.authDidFail.next(true)
        that.authIsLoading.next(false)
        that.signInSubmit.next({status:'false',message:err.message})
      },
      newPasswordRequired: function(userAttributes, requiredAttributes) {
        delete userAttributes.email_verified;
        delete userAttributes.phone_number_verified;
        cognitUser.completeNewPasswordChallenge(password, userAttributes, this);
      },
      selectMFAType: function(challengeName, challengeParameters) {
        console.log(challengeParameters,challengeParameters.CODE_DELIVERY_DESTINATION)
        // var mfaType = prompt('Please select the MFA method.', ''); // valid values for mfaType is "SMS_MFA", "SOFTWARE_TOKEN_MFA"
        // cognitUser.sendMFASelectionAnswer(mfaType, this);
        that.savedCognitoUser = cognitUser
        that.signInSubmit.next({status:'select-mfa',sms_delivery_phone:challengeParameters.CODE_DELIVERY_DESTINATION})
      },
      totpRequired: function () {
        that.savedCognitoUser = cognitUser
        that.signInSubmit.next({status:'software-token-mfa-input'})
        // console.log('mfa required')
        // const verificationCode = prompt('Please input verification code', '');
        // cognitUser.sendMFACode(verificationCode, this,"SOFTWARE_TOKEN_MFA");
      },
      mfaRequired:function (challengeName,codeDeliveryDetails) {
        that.savedCognitoUser = cognitUser
        that.signInSubmit.next({status:'sms-mfa-input',sms_delivery_phone:codeDeliveryDetails.Destination})
        // console.log('mfa required')
        // const verificationCode = prompt('Please input verification code', '');
        // cognitUser.sendMFACode(verificationCode, this);
      }
    })

    // this.authStatusChanged.next(true);
    return;
  }
  getAuthenticatedUser() {
    return userPool.getCurrentUser()
  }
  logout() {
    const cogUser = this.getAuthenticatedUser()
    if(cogUser){
      cogUser.signOut()
    }
    this.authStatusChanged.next(false);

    this.sessionStorageService.clear()
    this.sessionExpirationCheckService.stopTokenExpirationCheck();
  }

  getUserAttribute(){
    const cognitoUser = this.getAuthenticatedUser();

    cognitoUser.getSession((err, session) => {

      cognitoUser.getUserAttributes((err, result) =>{
        if (err) {
          // Handle error
          // console.log(err)
        } else {
          let object = {};
          for (let i = 0; i < result.length; i++) {
            object[result[i].getName()]= result[i].getValue()
          }
          this.userLogged.next(object)
        }
      });
    })
  }

  getUserMFA(){

    this.authIsLoading.next(true)
    const cognitoUser = this.getAuthenticatedUser();
    cognitoUser.getSession((err, session)=> {
      cognitoUser.getMFAOptions( (err, mfaOptions) =>{
        this.authIsLoading.next(false)
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        if(mfaOptions && mfaOptions.length > 0 ){
          this.isEnableMFA.next(true)
        }else {
          this.isEnableMFA.next(false)
        }

      });
    })
  }

  async getMfaSetting(){
    return new Promise((resolve,reject)=>{

      const cognitoUser = this.getAuthenticatedUser();
      cognitoUser.getSession((err, session)=> {
        cognitoUser.getUserData((err, data) => {
          if (err) {
            console.log(err)
            resolve(null)
          }
          console.log(data)
          const { PreferredMfaSetting, UserMFASettingList } = data;
          resolve({ PreferredMfaSetting, UserMFASettingList } )
        });
      })
    })
  }

  onCurrentDevice(){

    const cognitoUser = this.getAuthenticatedUser();
    cognitoUser.getSession((err, session)=> {
      cognitoUser.getDevice({
        onSuccess: (result) =>{
          console.error(result)
          this.currentDevice.next(result)
        },
        onFailure: (err)=> {
          console.error(err)
          this.currentDevice.next(null)
        },
      });
    })
  }

  getCurrentDevice(){
    return new Promise((resolve,reject)=>{
      const cognitoUser = this.getAuthenticatedUser();
      cognitoUser.getSession((err, session)=> {
        cognitoUser.getCachedDeviceKeyAndPassword()
        // @ts-ignore
        resolve(cognitoUser.deviceKey)
        // console.log(cognitoUser)
        // cognitoUser.getDevice({
        //   onSuccess: (result)=> {
        //     console.log('call result: ' + result);
        //     resolve(result)
        //   },
        //   onFailure: function(err) {
        //     console.log(err)
        //     resolve(null)
        //   },
        // });
      })
    })
  }

  onDeviceList(){
    const cognitoUser = this.getAuthenticatedUser();
    cognitoUser.getSession((err, session)=> {

      cognitoUser.listDevices(60, null, {
        onSuccess: (result)=> {
          console.log('call result: ' + result);
          this.listDevices.next(result)
        },
        onFailure: function(err) {
          console.log(err)
          this.listDevices.next(null)
        },
      });
    })
  }
  onEnableMFA(){
    this.authIsLoading.next(true)
    const cognitoUser = this.getAuthenticatedUser();
    cognitoUser.getSession((err, session)=>{
      cognitoUser.enableMFA((err, result) =>{
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        this.isEnableMFA.next(true)
        this.authIsLoading.next(false)
      });
    })
    return;
  }
  onDisableMFA(){
    this.authIsLoading.next(true)
    const cognitoUser = this.getAuthenticatedUser();
    cognitoUser.getSession((err, session)=>{
      cognitoUser.disableMFA((err, result) =>{
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        this.isEnableMFA.next(false)
        this.authIsLoading.next(false)
      });
    })
    return;
  }
  isAuthenticated(): Observable<boolean> {
    const user = this.getAuthenticatedUser();
    const obs = new Observable<boolean>((observer) => {
      if (! user) {
        observer.next(false);
      } else {
        user.getSession((err, session) => {
          if(err) {
            // console.log('error session 1')
            observer.next(false);
          }else{

            if(session.isValid()){
              // this.apiService.checkValidToken(session.getAccessToken().getJwtToken()).subscribe(res=>{
              //   if(res.status==="ERROR"){
              //     console.log('session invalid')
              //     observer.next(false);
              //   }else{
                  // console.log('session valid')
                  observer.next(true);
                // }
              // })

            }else{

              // console.log('error session 2')
              observer.next(false);
            }
          }
        })
      }
      observer.complete();
    });
    return obs;
  }


  verifyPhoneNumber(phone){
    const cognitoUser = this.getAuthenticatedUser();
    this.authIsLoading.next(true)
    cognitoUser.getSession((err, session)=>{
      // @ts-ignore
      let attributeList = [];

      let attribute = {
        Name: "phone_number",
        Value:  phone
      };
      let attribute_obj = new CognitoUserAttribute(attribute);
      attributeList.push(attribute_obj);

      cognitoUser.updateAttributes(attributeList, (err, result)=> {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }

        const that=this;
        cognitoUser.getAttributeVerificationCode('phone_number', {
          onSuccess: function () {
            that.authIsLoading.next(false)
            that.phoneVerify.next({status:'success'})
          },
          onFailure: function (er) {
            that.phoneVerify.next({status:'false',message:er.message || JSON.stringify(er)})
            that.authIsLoading.next(false)
          },
          inputVerificationCode: function () {
            that.phoneVerify.next({status:'verify-code'})
            that.authIsLoading.next(false)
          }
        });
      });
    });
  }


  verifyPhoneNumberCode(code){
    const cognitoUser = this.getAuthenticatedUser();
    this.authIsLoading.next(true)
    cognitoUser.getSession((err, session)=>{
      
      const that=this;
      cognitoUser.verifyAttribute('phone_number',code, {
        onSuccess: function () {
          that.authIsLoading.next(false)
          that.enableMFASms()
        },
        onFailure: function (er) {
          that.phoneVerify.next({status:'false',message:er.message || JSON.stringify(er)})
          that.authIsLoading.next(false)
        }
      });
    });
  }

  forgotPassword(email: string) {
    this.authIsLoading.next(true);
    const userData = {
      Username: email,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData)
    const that= this
    cognitoUser.forgotPassword({
      onSuccess: function(data) {
        that.authStatusChanged.next(false);
        that.forgotIsLoading.next(true);
        that.authIsLoading.next(false)
        // successfully initiated reset password request
        console.log('CodeDeliveryData from forgotPassword: ' + JSON.stringify(data));
      },
      onFailure: function(err) {
        that.authIsLoading.next(false)
        that.authStatusChanged.next(false);
        that.forgotIsLoading.next(false);
        alert(err.message || JSON.stringify(err));
      }
    });
  }

  changeNewPassword(email: string,code:string,password:string){
    const userData = {
      Username: email,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;
    cognitoUser.confirmPassword(code, password, {
      onSuccess() {
        alert('Password confirmed!');
        window.location.reload();
      },
      onFailure(err) {
        alert(err.message || JSON.stringify(err))
      },
    });
  }
  initAuth() {
    this.isAuthenticated().subscribe(
      (auth) => this.authStatusChanged.next(auth)
    );
  }


  enableMFASms(){
    const smsMfaSettings = {
      PreferredMfa: false,
      Enabled: true,
    };

    const cognitoUser = this.getAuthenticatedUser();
    cognitoUser.getSession((err, session)=> {
      cognitoUser.setUserMfaPreference(
        smsMfaSettings,
        null,
         (err, result)=> {
          if (err) {
            this.phoneVerify.next({status:'success',message:err.message || JSON.stringify(err)})
          }
          this.phoneVerify.next({status:'success'})
        }
      );
    })
  }

  enableMFA(){
    const totpMfaSettings = {
      PreferredMfa: false,
      Enabled: true,
    };

    const cognitoUser = this.getAuthenticatedUser();
    cognitoUser.getSession((err, session)=> {
      cognitoUser.setUserMfaPreference(
        null,
        totpMfaSettings,
         (err, result)=> {
          if (err) {
            alert(err.message || JSON.stringify(err));
          }
          this.onAuthenticatorApp.next('mfa_success')
        }
      );
    })
  }

  onEnableMFAMethod(){
    const cognitoUser = this.getAuthenticatedUser();
    cognitoUser.getSession((err, session)=> {
      cognitoUser.associateSoftwareToken({
        associateSecretCode: (secretCode)=> {
          console.info("AssociateSecretCode");
          console.info("Display QR Code");
          this.onAuthenticatorApp.next(secretCode)
        },
        onFailure: function(err) {
          console.log(err)
        },
      });
    })    
    
  }

  async removeAuthenticator(email,password){
    var authenticationData = {
      Username: email,
      Password: password,
    };
    var authenticationDetails = new AuthenticationDetails(
      authenticationData
    );
    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);

    // cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    const that = this
    
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) =>{
        console.log('suc')
        const totpMfaSettings = {
          PreferredMfa: false,
          Enabled: false,
        };    
        cognitoUser.setUserMfaPreference(null, totpMfaSettings, (err, result)=> {
          if (err) {
            alert(err.message || JSON.stringify(err));
          }
          this.onAuthenticatorApp.next('remove_authentication_success')
        });
      },
      onFailure: function(err) {
        console.log('er')
        that.phoneVerify.next('remove_authentication_error')
        // User authentication was not successful
      },
      totpRequired: function () {
        
        console.log('mfa required')
        const verificationCode = prompt('Please input verification code', '');
        cognitoUser.sendMFACode(verificationCode, this,"SOFTWARE_TOKEN_MFA");
      },
      mfaRequired:function () {
        console.log('mfa required')
        const verificationCode = prompt('Please input verification code', '');
        cognitoUser.sendMFACode(verificationCode, this);
      }
    });
  }

  async removeAuthenticatorSms(email,password){
    var authenticationData = {
      Username: email,
      Password: password,
    };
    var authenticationDetails = new AuthenticationDetails(
      authenticationData
    );
    var userData = {
      Username: email,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);

    // cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    const that = this
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) =>{
        console.log('suc')
        const smsMfaSettings = {
          PreferredMfa: false,
          Enabled: false,
        };    
        cognitoUser.setUserMfaPreference(smsMfaSettings, null, (err, result)=> {
          if (err) {
            alert(err.message || JSON.stringify(err));
          }
          that.phoneVerify.next({status:'remove_authentication_success'})
        });
      },
      onFailure: function(err) {
        console.log('er')
        // User authentication was not successful
        that.phoneVerify.next({status:'remove_authentication_error',message:err.message || JSON.stringify(err)})
      },
      totpRequired: function () {
        
        console.log('mfa required')
        const verificationCode = prompt('Please input verification code', '');
        cognitoUser.sendMFACode(verificationCode, this,"SOFTWARE_TOKEN_MFA");
      },
      mfaRequired:function () {
        console.log('mfa required')
        const verificationCode = prompt('Please input verification code', '');
        cognitoUser.sendMFACode(verificationCode, this);
      }
    });
  }

  verifyAuthenticator(code){
    const cognitoUser = this.getAuthenticatedUser();
    cognitoUser.getSession((err, session)=> {
      cognitoUser.verifySoftwareToken(
        code,
        "My device",
        {
          onFailure: function (err) {
            console.error(err.message || JSON.stringify(err));
            alert(err.message)
          },
          onSuccess:  (result) =>{
            console.info(result);
            this.enableMFA();
          },
        }
      );

    })    
  }


}
